window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());

document.addEventListener("turbo:load", function (event) {
  // gtag("config", "UA-185376264-1", {
  //   page_location: event.detail.url,
  //   page_path: window.location.pathname,
  //   page_title: event.srcElement.title,
  // });
  gtag('config', 'G-MWQM7JJ7SM');
});

export default gtag;
